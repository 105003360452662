import axios from 'axios';
import newRelic from '@/services/new-relic';

/**
 * Returning the env variables.
 * When none has been set, we will fetch the values.
 *
 * @returns {Object}
 */

let environmentVariables;

class EnvironmentVariables {
  values = {};

  static getInstance() {
    if (!environmentVariables) {
      environmentVariables = new EnvironmentVariables();
    }

    return environmentVariables;
  }

  fetch() {
    return axios.get('/env/env.json')
      .then((response) => {
        this.values = response.data;

        return this.values;
      }, (e) => {
        newRelic.noticeError(e);

        return this.values;
      });
  }

  get variables() {
    return this.values;
  }
}

export default EnvironmentVariables.getInstance();
