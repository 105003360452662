/**
 * @returns {boolean}
 */
const doesNewRelicExist = () => {
  // Validate if the newRelic functionality exists.
  if (typeof window.newrelic === 'undefined') {
    return false;
  }

  return true;
};

export default {
/**
 * @param {Error} error
 */
  noticeError: function(error) {
    if (!doesNewRelicExist()) {
      return;
    }

    window.newrelic.noticeError(error);
  },

  pageView: function(page) {
    if (!doesNewRelicExist()) {
      return;
    }

    window.newrelic.setPageViewName(page);
  },
};
